import { Pipe, PipeTransform } from '@angular/core';

import { ALL_LANGUAGES } from '../../localization/localization.constants';

import { GlobalService } from './../../services/global/global.service';

@Pipe({ name: 'course' })
export class CoursePipe implements PipeTransform {
  public languages = [];

  constructor(private globalService: GlobalService) {
    const CURRENT_USER_LANG = _.get(this, 'globalService.user.lng');

    if (ALL_LANGUAGES.includes(CURRENT_USER_LANG)) {
      this.languages.push(CURRENT_USER_LANG);
    }

    ALL_LANGUAGES.forEach((lng) => {
      if (!this.languages.includes(lng)) {
        this.languages.push(lng);
      }
    });
  }

  public transform(course): string {
    if (!course) {
      return '';
    }

    for (const lang of this.languages) {
      if (course[`title_${lang}`]) {
        return course[`title_${lang}`];
      }
    }

    return course.title;
  }
}
