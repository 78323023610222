import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';

import { AvatarComponent } from './avatar.component';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  declarations: [AvatarComponent],
  imports: [
    CommonModule,
    GalleryModule,
    LightboxModule,
    MatTooltipModule,
    I18NextModule,
  ],
  exports: [AvatarComponent],
})
export class AvatarModule {}
