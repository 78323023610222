import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { PermissionsService } from '../permissions/permissions.service';
import { UserService } from '../user/user.service';

@Injectable()
export class WorkhistoryGuardService implements CanActivate {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
    private userService: UserService,
  ) {}

  public canActivate(): boolean {
    if (
      !this.permissionsService.checkPermission({
        name: 'calendar_watch_any_user',
        user: this.userService.current_profile._id,
        schools: this.userService.current_profile.schools,
      })
    ) {
      this.router.navigate(['/error/403']);
      return false;
    }

    return true;
  }
}
