import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';

import { HttpService } from '../http/http.service';

@Injectable()
export class PollService {
  constructor(private http: HttpService) {}

  public getSchoolPolls(school_id: string, skip = 0): Observable<any> {
    const params = new HttpParams().append('skip', `${skip}`);

    return this.http.get(`/api/polls/school/${school_id}`, { params });
  }

  public getTeacherPolls(skip = 0): Observable<any> {
    const params = new HttpParams().append('skip', `${skip}`);

    return this.http.get('/api/polls', { params });
  }

  public getGroupPolls(group_id: string): Observable<any> {
    return this.http.get(`/api/polls/group/${group_id}`);
  }

  public sendGroupPoll(
    group_id: string,
    value: { school: string; poll_type: string },
  ): Observable<any> {
    return this.http.post(`/api/polls/groups/${group_id}`, value);
  }

  public getStudentPolls(student_id: string): Observable<any> {
    return this.http.get(`/api/polls/student/${student_id}`);
  }

  public checkPoll(poll_id: string): Observable<any> {
    return this.http.get(`/api/polls/${poll_id}/check`);
  }

  public getPollFeedbacks(poll_id: string): Observable<any> {
    return this.http.get(`/api/polls/${poll_id}/feedbacks`);
  }

  public deletePoll(poll_id: string): Observable<any> {
    return this.http.delete(`/api/polls/${poll_id}`);
  }

  public deletePollAnswer(feedback_id: string): Observable<any> {
    return this.http.delete(`/api/polls/feedbacks/${feedback_id}`);
  }

  public createQuestion(value: {
    title: string;
    title_min: string;
    slug: string;
    question_type: string;
    answers: Array<{ title: string; value: any }>;
  }): Observable<any> {
    return this.http.post(`/api/polls/questions`, value);
  }

  public updateQuestion(
    question_id,
    value: {
      title: string;
      title_min: string;
      slug: string;
      question_type: string;
      answers: Array<{ title: string; value: any }>;
    },
  ): Observable<any> {
    return this.http.put(`/api/polls/questions/${question_id}`, value);
  }

  public deleteQuestion(question_id: string): Observable<any> {
    return this.http.delete(`/api/polls/questions/${question_id}`);
  }

  public getQuestions(select = false): Observable<any> {
    let params = new HttpParams();

    params = params.append('select', `${select}`);

    return this.http.get(`/api/polls/questions`, { params });
  }

  public getQuestionsTypes(): Observable<any> {
    return of({
      success: true,
      response: [
        {
          label: 'page-tt-poll-questions-add:one_of_three',
          name: 'range-3',
        },
        {
          label: 'page-tt-poll-questions-add:from_1_to_10',
          name: 'range-10',
        },
        {
          label: 'page-tt-poll-questions-add:detailed_response',
          name: 'text',
        },
      ],
    });
  }

  public createPollType(value: {
    title: string;
    questions: Array<any>;
  }): Observable<any> {
    return this.http.post(`/api/polls/types`, value);
  }

  public updatePollType(
    type_id,
    value: {
      title: string;
      questions: Array<any>;
    },
  ): Observable<any> {
    return this.http.put(`/api/polls/types/${type_id}`, value);
  }

  public deletePollType(type_id: string): Observable<any> {
    return this.http.delete(`/api/polls/types/${type_id}`);
  }

  public getPollTypes(
    select = false,
    for_student_role = false,
  ): Observable<any> {
    let params = new HttpParams();

    params = params.append('select', `${select}`);
    params = params.append('student', `${for_student_role}`);

    return this.http.get(`/api/polls/types`, { params });
  }
}
