import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable()
export class DeactivePageGuard implements CanDeactivate<any> {
  public canDeactivate(component: any): boolean {
    return component.canDeactivate().pipe(map((can) => can));
  }
}
