import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'parse_links' })
export class ParseLinksPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: string, inverted?: boolean): any {
    if (!value) return;

    const link_exp = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gi;

    return value.replace(
      link_exp,
      `<a href="$1" target="_blank" class="link ${
        inverted ? 'link--inverted' : ''
      }">$1</a>`,
    );
  }
}
