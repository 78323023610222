import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { HttpService } from '../../services/http/http.service';
import { encodeSpecialChars } from '@shared/common';

@Injectable()
export class CoursesService {
  constructor(private http: HttpService) {}

  public getCourses(query?: {
    types?: string[];
    type?: string;
  }): Observable<any> {
    const params: any = {};

    if (query?.type) params.type = query.type;
    if (query?.types?.length) params['types[]'] = query.types;

    return this.http.get('/api/courses', {
      params: new HttpParams({
        fromObject: params,
      }),
    });
  }

  public getCoursesAll(query: {
    skip: number;
    limit: number;
    search: string;
    sort: string;
    direction: string;
    types: Array<'regular' | 'sprint'>;
    archive: boolean;
    exclude: boolean;
  }): Observable<any> {
    let params = new HttpParams({
      fromObject: {
        skip: query.skip,
        limit: query.limit,
        archive: !!query.archive,
        exclude: !!query.exclude,
        'types[]': query.types || [],
      },
    });

    if (query.sort) {
      params = params.set('sort', query.sort);
    }

    if (query.direction) {
      const dir = query.direction === 'asc' ? '1' : '-1';

      params = params.set('direction', dir);
    }

    if (query.search) {
      params = params.set('search', encodeSpecialChars(query.search));
    }

    return this.http.get('/api/courses/all', { params });
  }

  public getCourse(course_id: string): Observable<any> {
    return this.http.get(`/api/courses/${course_id}`);
  }

  public addCourse(value: string): Observable<any> {
    return this.http.post('/api/courses', value);
  }

  public getVideoCourse(): Observable<any> {
    return this.http.get('/api/videocourses');
  }

  public updateCourse(course_id: string, value): Observable<any> {
    return this.http.put(`/api/courses/${course_id}`, value);
  }

  public checkCourse(course_id: string): Observable<any> {
    return this.http.get(`/api/courses/${course_id}/check`);
  }

  public getCourseEvents(): Observable<any> {
    return this.http.get(`/api/course-events`);
  }

  public getCourseEventsFromCourse(): Observable<any> {
    return this.http.get(`/api/course-events/course`);
  }

  public addCourseEvent(value: {
    title: string;
    slug: string;
  }): Observable<any> {
    return this.http.post(`/api/course-events`, value);
  }

  public updateCourseEvent(
    event_id: string,
    value: { title: string; slug: string },
  ): Observable<any> {
    return this.http.put(`/api/course-events/${event_id}`, value);
  }

  public deleteCourse(course_id: string): Observable<any> {
    return this.http.delete(`/api/courses/${course_id}`);
  }

  public getAllCoursesArchive(): Observable<any> {
    return this.http.get(`/api/courses/archive`);
  }

  public searchCourses({
    search = '',
    student,
    ids,
    employment_required,
  }: {
    search?: string;
    student?: string;
    ids?: string[];
    employment_required?: boolean;
  }): Observable<any> {
    let params = new HttpParams({
      fromObject: {
        'ids[]': ids || [],
      },
    });

    if (search) {
      params = params.append('search', encodeSpecialChars(search));
    }

    if (student) {
      params = params.append('student', student);
    }

    if (employment_required) {
      params = params.append('employment_required', employment_required);
    }
    return this.http.get(`/api/courses/search`, { params });
  }

  public getCoursePrograms(
    course_id: string,
    current_program?: string,
  ): Observable<any> {
    let params = new HttpParams();

    if (current_program) {
      params = params.append('current_program', current_program);
    }

    return this.http.get(`/api/courses/programme/course/${course_id}`, {
      params,
    });
  }
}
