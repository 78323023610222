import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { HttpService } from '../http/http.service';

@Injectable()
export class SchoolsService {
  constructor(private http: HttpService) {}

  public getSchool(school_id: string): Observable<any> {
    return this.http.get(`/api/schools/${school_id}`);
  }

  public getSchools(select?: boolean): Observable<any> {
    return this.http.get(`/api/schools${select ? '?select=true' : ''}`);
  }

  public addSchool(value): Observable<any> {
    return this.http.post('/api/schools', value);
  }

  public updateSchool(school_id: string, value): Observable<any> {
    return this.http.put(`/api/schools/${school_id}`, value);
  }

  public getClasses(school_id): Observable<any> {
    return this.http.get(`/api/schools/${school_id}/classes`);
  }

  public checkSchool(school_id: string): Observable<any> {
    return this.http.get(`/api/schools/${school_id}/check`);
  }
}
