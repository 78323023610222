import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class CouponsGuardService implements CanActivate {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
  ) {}

  public canActivate(): boolean {
    if (!this.permissionsService.checkPermission({ name: 'coupons' })) {
      this.router.navigate(['/error/403']);
      return false;
    }

    return true;
  }
}
