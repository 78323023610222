import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { CoursesService } from '../courses/courses.service';

@Injectable()
export class CourseCheckGuardService implements CanActivate {
  constructor(private router: Router, private coursesService: CoursesService) {}

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const course = await this.coursesService
        .checkCourse(route.params.course_id)
        .toPromise();

      if (!course.success) {
        throw new Error('Course Not Found');
      }

      return course.success;
    } catch (error) {
      this.router.navigate(['/error/404']);
      return false;
    }
  }
}
