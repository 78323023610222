import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UserService } from '../../services/user/user.service';
import { ApplicationDirectivesModule } from '../../shared/application-directives.module';
import { AlertModule } from '../alert/alert.module';
import { AvatarModule } from '../avatar/avatar.module';
import { ProfilePhotoModule } from '../profile-photo/profile-photo.module';

import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { I18NextModule } from 'angular-i18next';
import { NamePipe } from '../../shared/pipes/name.pipe';
import { ApplicationPipesModule } from '@shared/pipes/application-pipes.module';
import { PageHeaderModule } from '@components/page-header/page-header.module';
import { RouterModule } from '@angular/router';
import { LayoutDialogModule } from './layout-dialog/layout-dialog.module';
import { LayoutQuickSearchDialogModule } from './layout-quick-search-dialog/layout-quick-search-dialog.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [LayoutComponent],
  providers: [UserService, NamePipe],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatListModule,
    ApplicationDirectivesModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    AlertModule,
    MatInputModule,
    ProfilePhotoModule,
    AvatarModule,
    I18NextModule,
    ApplicationPipesModule,
    PageHeaderModule,
    RouterModule,
    LayoutDialogModule,
    LayoutQuickSearchDialogModule,
    MatDialogModule,
  ],
})
export class LayoutModule {}
