import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UserService } from '../user/user.service';

@Injectable()
export class AccountStudentRolesGuardService implements CanActivate {
  public get current_profile(): any {
    return this.userService.current_profile;
  }

  constructor(private router: Router, private userService: UserService) {}

  public async canActivate(): Promise<boolean> {
    try {
      if (!this.current_profile.roles.includes('student')) {
        throw new Error('Permission Not Found');
      }

      return true;
    } catch (error) {
      this.router.navigate(['/error/404']);
      return false;
    }
  }
}
