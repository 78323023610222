import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@services/http/http.service';

@Injectable()
export class WorkbookService {
  private _active_workbook: any;

  public get active_workbook(): any {
    return this._active_workbook;
  }
  public set active_workbook(value: any) {
    this._active_workbook = value;
  }

  public get default_lang(): string {
    return this.active_workbook?.languages?.[0];
  }

  constructor(private http: HttpService) {}

  public getWorkbooks(value: {
    skip: number;
    limit: number;
    status?: string[];
    search?: string;
    updated_from?: string | Date;
    updated_to?: string | Date;
  }): Observable<any> {
    const query: any = {
      skip: value.skip,
      limit: value.limit,
      'status[]': value.status || [],
    };

    if (value.search) {
      query.search = value.search;
    }

    if (value.updated_from) {
      query.updated_from = value.updated_from;
    }

    if (value.updated_to) {
      query.updated_to = value.updated_to;
    }

    const params = new HttpParams({ fromObject: query });

    return this.http.get('/api/workbooks', { params });
  }

  public createNewWorkbook(value: any): Observable<any> {
    return this.http.post('/api/workbooks', value);
  }

  public updateWorkbook(workbook_id: string, value: any): Observable<any> {
    return this.http.put(`/api/workbooks/${workbook_id}`, value);
  }

  public cloneWorkbook(workbook_id: string): Observable<any> {
    return this.http.post(`/api/workbooks/${workbook_id}/clone`, {});
  }

  public getWorkbook(workbook_id: string): Observable<any> {
    return this.http.get(`/api/workbooks/${workbook_id}`);
  }

  public getWorkbookTheory(workbook_id: string, lng: string): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${lng}` });

    return this.http.get(`/api/workbooks/${workbook_id}/theory`, { params });
  }

  public updateWorkbookTheory(
    workbook_id: string,
    value: any,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${value.lang}` });

    return this.http.put(`/api/workbooks/${workbook_id}/theory`, value, {
      params,
    });
  }

  public createWorkbookChapter(
    workbook_id: string,
    value: any,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${value.lng}` });

    return this.http.post(
      `/api/workbooks/${workbook_id}/theory/chapter`,
      value,
      { params },
    );
  }

  public updateWorkbookChapter(
    workbook_id: string,
    chapter_id: string,
    value: any,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${value.lng}` });

    return this.http.put(
      `/api/workbooks/${workbook_id}/theory/${chapter_id}`,
      value,
      { params },
    );
  }

  public deleteWorkbookElement(
    workbook_id: string,
    element_id: string,
    lng: string,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${lng}` });

    return this.http.delete(
      `/api/workbooks/${workbook_id}/theory/${element_id}`,
      { params },
    );
  }

  public getWorkbookChapters(
    workbook_id: string,
    lng: string,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${lng}` });

    return this.http.get(`/api/workbooks/${workbook_id}/theory/chapters`, {
      params,
    });
  }

  public getArticle(
    workbook_id: string,
    article_id: string,
    lng: string,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${lng}` });

    return this.http.get(
      `/api/workbooks/${workbook_id}/theory/article/${article_id}`,
      { params },
    );
  }

  public createArticle(workbook_id: string, value: any): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${value.lang}` });

    return this.http.post(
      `/api/workbooks/${workbook_id}/theory/article`,
      value,
      { params },
    );
  }

  public updateWorkbookArticle(
    workbook_id: string,
    article_id: string,
    value: any,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${value.lang}` });

    return this.http.put(
      `/api/workbooks/${workbook_id}/theory/article/${article_id}`,
      value,
      { params },
    );
  }

  public getWorkbookLessons(
    workbook_id: string,
    lng: string,
    search?: string,
  ): Observable<any> {
    let params = new HttpParams({ fromObject: { lng } });

    if (search) {
      params = params.append('search', search);
    }

    return this.http.get(`/api/workbooks/${workbook_id}/lessons`, { params });
  }

  public getWorkbookUsedArticles(
    workbook_id: string,
    lng: string,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${lng}` });

    return this.http.get(`/api/workbooks/${workbook_id}/theory/used-articles`, {
      params,
    });
  }

  public getWorkbookLesson(
    workbook_id: string,
    lesson_id: string,
    lng: string,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${lng}` });

    return this.http.get(`/api/workbooks/${workbook_id}/lessons/${lesson_id}`, {
      params,
    });
  }

  public createWorkbookLesson(
    workbook_id: string,
    value: any,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${value.lang}` });

    return this.http.post(`/api/workbooks/${workbook_id}/lessons`, value, {
      params,
    });
  }

  public updateWorkbookLesson(
    workbook_id: string,
    lesson_id: string,
    value: any,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${value.lang}` });

    return this.http.put(
      `/api/workbooks/${workbook_id}/lessons/${lesson_id}`,
      value,
      { params },
    );
  }

  public getWorkbookAllPractics(
    workbook_id: string,
    value: {
      lng: string;
      skip: number;
      limit: number;
      search: string;
      require_type?: string;
      type?: string;
      updated_from?: string;
      updated_to?: string;
    },
  ): Observable<any> {
    let params = new HttpParams({
      fromObject: {
        skip: value.skip,
        limit: value.limit,
        lng: value.lng,
      },
    });

    if (value.search) {
      params = params.append('search', value.search);
    }

    if (value.require_type) {
      params = params.append('require_type', value.require_type);
    }

    if (value.type) {
      params = params.append('type', value.type);
    }

    if (value.updated_from) {
      params = params.append('updated_from', value.updated_from);
    }

    if (value.updated_to) {
      params = params.append('updated_to', value.updated_to);
    }

    return this.http.get(`/api/workbooks/${workbook_id}/practics`, { params });
  }

  public searchWorkbooks(query: string): Observable<any> {
    return this.http.get(
      '/api/workbooks/search',
      query
        ? { params: new HttpParams({ fromObject: { search: query } }) }
        : {},
    );
  }

  public searchCodeLanguages(search: string): Observable<any> {
    const params = new HttpParams({ fromString: `search=${search}` });

    return this.http.get('/api/workbooks/code-languages', { params });
  }

  public updateWorkbookLessons(
    workbook_id: string,
    lng: string,
    value: Array<{ _id: string; number: number }>,
  ): Observable<any> {
    const params = new HttpParams({ fromString: `lng=${lng}` });

    return this.http.put(`/api/workbooks/${workbook_id}/lessons`, value, {
      params,
    });
  }

  public getWorkbookLocks(workbook_id: string): Observable<any> {
    return this.http.get(`/api/workbooks/${workbook_id}/locks`);
  }
}
