import { Pipe, PipeTransform } from '@angular/core';

import { Declension } from './../../constants/constants';

@Pipe({ name: 'declension' })
export class DeclensionPipe implements PipeTransform {
  public transform(value: number, words: string[]): string {
    const number_abs = Math.abs(value);
    const cases = [2, 0, 1, 1, 1, 2];

    const m_100 = number_abs % Declension.HUNDREDS;
    const m_10 = number_abs % Declension.DOZENS;
    const m_10_on_10 = number_abs % m_10;

    const first = m_10_on_10 < Declension.BIGGER;
    const second = m_100 >= Declension.BIGGER && m_100 < Declension.SMALLER;

    return `${value} ${
      words[second ? 2 : cases[first ? m_10_on_10 : Declension.BIGGER]]
    }`;
  }
}
