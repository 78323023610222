import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { BUDGETS_PERMISSIONS_BY_PATH } from '../../shared/common';
import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class BudgetGuardService implements CanActivate {
  private permissions_by_path = BUDGETS_PERMISSIONS_BY_PATH;

  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    let permission_config;

    if (route.routeConfig.path === 'budget') {
      permission_config = Object.keys(this.permissions_by_path).map((key) => ({
        name: this.permissions_by_path[key],
      }));
    } else {
      permission_config = [
        {
          name: this.permissions_by_path[route.routeConfig.path],
        },
      ];
    }

    if (!this.permissionsService.checkOneOfAll(permission_config)) {
      this.router.navigate(['/error/403']);
      return false;
    }

    return true;
  }
}
