import { Pipe, PipeTransform } from '@angular/core';

import { LANGUAGES } from '../../localization/localization.constants';

import { GlobalService } from './../../services/global/global.service';

@Pipe({ name: 'name' })
export class NamePipe implements PipeTransform {
  public languages = [];

  constructor(private globalService: GlobalService) {
    if (this.globalService.user && this.globalService.user.lng) {
      this.languages.push(this.globalService.user.lng);
    }

    this.languages.push(...Object.keys(LANGUAGES));
  }

  public transform(user, key = 'full'): string {
    if (!user) {
      return '';
    }

    for (const lang of this.languages) {
      const first = `first_name_${lang}`;
      const last = `last_name_${lang}`;
      const not_full = `${key}_name_${lang}`;

      if (key === 'full' && user[first] && user[last]) {
        return `${user[first]} ${user[last]}`;
      } else if (user[not_full]) {
        return user[not_full];
      }
    }

    if (key === 'full') {
      return `${user.first_name || ''} ${user.last_name || ''}`.trim();
    } else {
      return user[`${key}_name`] || '';
    }
  }
}
