import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UserFilters } from '@constants/constants';
import { InterviewsService } from '@services/interviews/interviews.service';
import { UserService } from '@services/user/user.service';
import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class InterviewsGuardService implements CanActivate {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
    private interviewsService: InterviewsService,
    private userService: UserService,
  ) {}

  public async canActivate(): Promise<boolean> {
    try {
      if (
        !this.permissionsService.checkPermission({
          name: 'interviews',
        })
      ) {
        this.router.navigate(['/error/403']);
        return false;
      }

      const resolve = await this.userService
        .getUserFilters(UserFilters.INTERVIEWS_PAGE_FILTER)
        .toPromise();

      if (resolve.success) {
        this.interviewsService.filters = resolve.response || {};
      }

      return true;
    } catch (e) {
      this.router.navigate(['/error/404']);
      return false;
    }
  }
}
