import { Pipe, PipeTransform } from '@angular/core';

import { CoursePipe } from './course.pipe';
import { NamePipe } from './name.pipe';

@Pipe({ name: 'group_name' })
export class GroupNamePipe implements PipeTransform {
  constructor(private namePipe: NamePipe, private coursePipe: CoursePipe) {}

  public transform(group): string {
    if (!group) {
      return '';
    }

    const course = group.course ? this.coursePipe.transform(group.course) : '';
    const start_date = moment(group.start_date).format('DD.MM.YY');
    const coaches = group.coaches
      ? group.coaches.reduce((acc, coach, index) => {
          acc += `${index ? ', ' : ''}${this.namePipe.transform(
            coach,
            'last',
          )}`;
          return acc;
        }, '')
      : '';

    return `${course} ${start_date ? `(${start_date})` : ''} ${coaches}`;
  }
}
