<div class="layout-quick-search-dialog-header">
  <mat-icon class="layout-quick-search-dialog-header__icon">
    search
  </mat-icon>
  <mat-form-field class="layout-quick-search-dialog-header__field">
    <input
      matInput
      #search_input
      class="layout-quick-search-dialog-header__input"
      type="text"
      [formControl]="search_control"
      (keydown)="search_change$.next($event)"
      [placeholder]="'page-tt-layout:link_name' | i18next"
    />
  </mat-form-field>
</div>
<div class="layout-quick-search-dialog-content">
  <ng-container
    [ngTemplateOutlet]="list"
    [ngTemplateOutletContext]="{
      $implicit: start_search && locals_items.length ? locals_items : items
    }"
  >
  </ng-container>

  <ng-template #list let-items>
    <ul
      #content_list
      class="layout-quick-search-dialog-content__list"
      *ngIf="items.length; else no_search_content"
    >
      <li
        #content_item
        class="layout-quick-search-dialog-content__item"
        *ngFor="let item of items"
        (click)="onResultClick(item)"
      >
        <a
          *ngIf="item.type === 'exit'; else another_type"
          class="layout-quick-search-dialog-content__link"
          [class.layout-quick-search-dialog-content__link--active]="
            item.is_active
          "
          (click)="logout()"
        >
          <ng-container [ngTemplateOutlet]="link_content"></ng-container>
        </a>

        <ng-template #another_type>
          <a
            class="layout-quick-search-dialog-content__link"
            [class.layout-quick-search-dialog-content__link--active]="
              item.is_active
            "
            [routerLink]="item.router"
            [queryParams]="item.params ? item.params : null"
          >
            <ng-container [ngTemplateOutlet]="link_content"></ng-container>
          </a>
        </ng-template>

        <ng-template #link_content>
          <mat-icon
            class="layout-quick-search-dialog-content__icon"
            [class.layout-quick-search-dialog-content__icon--exit]="
              item.type === 'exit'
            "
          >
            {{ item.icon }}
          </mat-icon>
          <span class="layout-quick-search-dialog-content__texts">
            <span
              class="layout-quick-search-dialog-content__text"
              [class.layout-quick-search-dialog-content__text--exit]="
                item.type === 'exit'
              "
            >
              {{ item.title | i18next }}
            </span>
            <span class="layout-quick-search-dialog-content__section">
              {{ 'page-tt-layout:section_' + item.section | i18next }}
            </span>
          </span>
          <mat-icon
            class="layout-quick-search-dialog-content__icon layout-quick-search-dialog-content__icon--enter"
          >
            keyboard_return
          </mat-icon>
        </ng-template>
      </li>
    </ul>
  </ng-template>

  <ng-template #no_search_content>
    <span
      class="layout-quick-search-dialog-content__no-content"
      *ngIf="start_search && !locals_items.length"
    >
      {{ 'page-tt-layout:type_something_for_search' | i18next }}...
    </span>
    <span
      class="layout-quick-search-dialog-content__no-content"
      *ngIf="nothing_found"
    >
      {{ 'page-tt-layout:nothing_found' | i18next }}
    </span>
  </ng-template>
</div>

<div class="layout-quick-search-dialog-footer">
  <mat-icon class="layout-quick-search-dialog-footer__icon">
    arrow_upward
  </mat-icon>
  <mat-icon class="layout-quick-search-dialog-footer__icon">
    arrow_downward
  </mat-icon>
  <span class="layout-quick-search-dialog-footer__item">
    {{ 'page-tt-layout:for_navigation' | i18next }},
  </span>
  <mat-icon class="layout-quick-search-dialog-footer__icon">
    keyboard_return
  </mat-icon>
  <span class="layout-quick-search-dialog-footer__item">
    {{ 'page-tt-layout:esc_for_closing' | i18next }}
  </span>
</div>
