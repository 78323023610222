import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { GroupsService } from '../groups/groups.service';
import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class GroupCheckAndSchoolAccessGuardService implements CanActivate {
  constructor(
    private router: Router,
    private groupService: GroupsService,
    private permissionsService: PermissionsService,
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const group = await this.groupService
        .checkGroup(route.params.group_id)
        .toPromise();

      if (!group.success) {
        throw new Error('Group Not Found');
      }

      if (
        !this.permissionsService.checkPermission({
          name: 'groups',
          schools: [group.response.school._id],
        })
      ) {
        this.router.navigate(['/error/403']);
        return false;
      }

      return group.success;
    } catch (error) {
      this.router.navigate(['/error/404']);
      return false;
    }
  }
}
