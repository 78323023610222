import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { HttpService } from '../../services/http/http.service';

@Injectable()
export class WorkhistoryService {
  constructor(private http: HttpService) {}

  public getWorkHistory(value: any): Observable<any> {
    return this.http.post(`/api/workhistorys`, value);
  }

  public checkin(value?: any): Observable<any> {
    return this.http.post(`/api/workhistorys/checkin`, value);
  }

  public checkout(value?: any): Observable<any> {
    return this.http.post(`/api/workhistorys/checkout`, value);
  }

  public addWorkHistoryConfirm(value: any): Observable<any> {
    return this.http.post(`/api/workhistorys/confirms`, value);
  }

  public getWorkHistoryConfirms(school_id: any): Observable<any> {
    return this.http.get(`/api/workhistorys/confirms/school/${school_id}`);
  }

  public acceptWorkHistoryConfirms(confirm_id: any): Observable<any> {
    return this.http.put(`/api/workhistorys/confirms/${confirm_id}`, {});
  }

  public archiveWorkHistoryConfirms(confirm_id: any): Observable<any> {
    return this.http.delete(`/api/workhistorys/confirms/${confirm_id}`);
  }
}
