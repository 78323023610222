<h2 mat-dialog-title class="layout-dialog__title">
  {{ 'page-tt-layout:quick_access' | i18next }}
</h2>
<mat-dialog-content>
  <ng-container *ngIf="items.length || data.length; else no_data">
    <ul
      *ngIf="items.length; else no_data"
      class="layout-dialog__list"
      cdkDropList
      cdkDropListLockAxis="y"
      (cdkDropListDropped)="sortItems($event)"
    >
      <li
        cdkDrag
        class="layout-dialog__item"
        *ngFor="let item of items; index as index"
      >
        <button cdkDragHandle class="layout-dialog__item-button">
          <mat-icon class="layout-dialog__item-icon">drag_handle</mat-icon>
        </button>
        <mat-icon
          class="layout-dialog__item-icon"
          [class.layout-dialog__item-icon--exit]="item.type === 'exit'"
        >
          {{ item.icon }}
        </mat-icon>
        <span
          class="layout-dialog__item-text"
          [class.layout-dialog__item-text--exit]="item.type === 'exit'"
        >
          {{
            (item.type === 'add' ? ('general:add' | i18next) + ' ' : '') +
              (item.title | i18next)
          }}
        </span>
        <button
          class="layout-dialog__item-button layout-dialog__item-button--delete"
          (click)="deleteItem(index)"
        >
          <mat-icon
            class="layout-dialog__item-icon layout-dialog__item-icon--delete"
          >
            delete
          </mat-icon>
        </button>
      </li>
    </ul>
  </ng-container>
  <ng-template #no_data>
    <div class="layout-dialog__no-data">
      <mat-icon class="layout-dialog__no-data-icon">push_pin</mat-icon>
      <span class="layout-dialog__no-data-text">
        {{ 'page-tt-layout:quick_access_text' | i18next }}.
      </span>
    </div>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions class="layout-dialog__actions">
  <button
    mat-raised-button
    class="layout-dialog__actions-button"
    color="primary"
    mat-dialog-close
  >
    {{ 'page-tt-layout:action_cancel' | i18next }}
  </button>
  <button
    *ngIf="items.length || data.length"
    type="submit"
    mat-raised-button
    class="layout-dialog__actions-button"
    color="accent"
    (click)="submit(items)"
  >
    {{ 'page-tt-layout:action_save' | i18next }}
  </button>
</mat-dialog-actions>
