import { Injectable } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { getImageUrl } from './common';

@Injectable()
export class ImageChecker {
  constructor(public sanitizer: DomSanitizer) {}

  public check(avatars?, size?): SafeStyle {
    const avatar = avatars ? avatars[0] : undefined;
    const url = getImageUrl({ image: avatar, size });

    return this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
  }
}
