import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutQuickSearchDialogComponent } from './layout-quick-search-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { I18NextModule } from 'angular-i18next';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    I18NextModule,
    MatDialogModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    RouterModule,
  ],
  declarations: [LayoutQuickSearchDialogComponent],
})
export class LayoutQuickSearchDialogModule {}
