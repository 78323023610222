export const environment = {
  production: false,
  name: 'devapi',
  apiUrl: 'https://devtt.hillel.it',
  socketUrl: 'https://devtt.hillel.it',
  lmsUrl: 'https://devlms.hillel.it',
  ttUrl: 'https://devtt.hillel.it',
  googleAnalytics: 'UA-XXXXXXXXX-X',
  posthog_api_key: 'phc_DS7MP1O9eT4cGgH3lZwe5g9jUZxS3joCLGkXWetocrS',
  posthog_api_host: 'https://eu.posthog.com',
  map_box: {
    access_token:
      'pk.eyJ1IjoiZm9taW51YSIsImEiOiJja2dobmcwazQxam1uMnlzMWExdDlkbmM0In0.KcAv6MPuS1WKTxCV6xaOKg',
  },
};
