import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'day_name' })
export class DayNamePipe implements PipeTransform {
  public transform(day_num: number, format): string {
    return moment()
      .day(day_num)
      .format(format);
  }
}
