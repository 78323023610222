import { Component, Input, OnInit } from '@angular/core';

import { ClickService } from '../../services/click/click.service';
import { ErrorService } from '../../services/error/error.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() public data;

  constructor(public errorService: ErrorService, private click: ClickService) {}

  public ngOnInit(): void {}

  public copyAlertBody(): void {
    this.click.copy(JSON.stringify(this.data));
    this.data.showed = true;
  }

  public sendNotification(): void {
    this.errorService.sendErrorReport(this.data).subscribe(({ success }) => {
      this.data.showed = true;
    });
  }
}
