import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { GlobalService } from '../global/global.service';

@Injectable()
export class LoginGuardService implements CanActivate {
  constructor(private globalService: GlobalService) {}

  public async canActivate(): Promise<boolean> {
    this.globalService.reset();
    return true;
  }
}
