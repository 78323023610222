import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

import { HeaderConfig } from '@components/page-header/page-header.constants';

@Injectable()
export class HeaderEventsService {
  public menu_closed$ = new Subject<void>();
  public action_clicked$ = new Subject<string>();

  private _header_config$ = new BehaviorSubject<HeaderConfig | null>(null);
  private _disable_element$ = new BehaviorSubject<{
    name: string;
    value: boolean;
  } | null>(null);

  public setHeaderElements(value: HeaderConfig | null): void {
    this._header_config$.next(value);
  }

  public getHeaderElements(): Observable<HeaderConfig | null> {
    return this._header_config$.asObservable();
  }

  public setDisableElement(
    value: { name: string; value: boolean } | null,
  ): void {
    this._disable_element$.next(value);
  }

  public getDisableElement(): Observable<{
    name: string;
    value: boolean;
  } | null> {
    return this._disable_element$.asObservable();
  }

  public clickAction(name: string): void {
    this.action_clicked$.next(name);
  }

  public reset(): void {
    this._header_config$.next(null);
    this._disable_element$.next(null);
  }
}
