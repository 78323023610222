import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'titlehyphencase' })
export class TitleHyphenPipe implements PipeTransform {
  public transform(input: string): string {
    if (!input) {
      return '';
    } else {
      return input.replace(/\b\w|-w/g, (first) => first.toLocaleUpperCase());
    }
  }
}
