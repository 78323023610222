import { Pipe, PipeTransform } from '@angular/core';

import { NamePipe } from './name.pipe';

@Pipe({ name: 'users' })
export class UsersPipe implements PipeTransform {
  constructor(private namePipe: NamePipe) {}

  public transform(
    users: any[] = [],
    options: { fields?: string; roles?: string[] } = {},
  ): string {
    if (!options.fields) options.fields = 'first last';
    if (!options.roles) options.roles = ['coach'];

    const user_fields = options.fields.split(' ');

    return users
      .reduce((acc: string[], user) => {
        if (user.role) {
          if (options.roles && !options.roles.includes(user.role)) {
            return acc;
          }

          user = user.user;
        }

        if (user_fields.length) {
          acc.push(
            user_fields
              .map((field) => this.namePipe.transform(user, field))
              .join(' '),
          );
          return acc;
        }

        acc.push(this.namePipe.transform(user));
        return acc;
      }, [])
      .join(', ')
      .trim();
  }
}
