import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { GlobalService } from '../global/global.service';

@Injectable()
export class UserLoadCheckGuardService implements CanActivate {
  get user(): any {
    return this.globalService.user;
  }

  constructor(private router: Router, private globalService: GlobalService) {}

  public canActivate(): boolean {
    if (this.user) return true;

    this.router.navigate(['/login']);

    return false;
  }
}
