import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { PollService } from '../poll/poll.service';

@Injectable()
export class PollCheckGuardService implements CanActivate {
  constructor(private router: Router, private pollService: PollService) {}

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const poll = await this.pollService
        .checkPoll(route.params.poll_id)
        .toPromise();

      if (!poll.success) {
        throw new Error('Poll Not Found');
      }

      if (poll.response.old_interview_id === route.params.poll_id) {
        this.router.navigate(['/', 'polls', poll.response._id]);
      }

      return poll.success;
    } catch (error) {
      this.router.navigate(['/error/404']);
      return false;
    }
  }
}
